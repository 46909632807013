<button
  type="button"
  class="btn btn-outline-primary"
  (click)="fileInput.click()"
>
  <ng-content>
    <i [attr.class]="uploadIconCssClass()"></i>
  </ng-content>
</button>
<input
  #fileInput
  [id]="fileId()"
  type="file"
  accept="image/*"
  (change)="onUpload(fileInput)"
/>
