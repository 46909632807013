[
  {
    "name": "United States",
    "countryCodes": ["1"],
    "isoCodes": ["US", "USA"]
  },
  {
    "name": "Afghanistan",
    "countryCodes": ["93"],
    "isoCodes": ["AF", "AFG"]
  },
  {
    "name": "Albania",
    "countryCodes": ["355"],
    "isoCodes": ["AL", "ALB"]
  },
  {
    "name": "Algeria",
    "countryCodes": ["213"],
    "isoCodes": ["DZ", "DZA"]
  },
  {
    "name": "American Samoa",
    "countryCodes": ["1-684"],
    "isoCodes": ["AS", "ASM"]
  },
  {
    "name": "Andorra",
    "countryCodes": ["376"],
    "isoCodes": ["AD", "AND"]
  },
  {
    "name": "Angola",
    "countryCodes": ["244"],
    "isoCodes": ["AO", "AGO"]
  },
  {
    "name": "Anguilla",
    "countryCodes": ["1-264"],
    "isoCodes": ["AI", "AIA"]
  },
  {
    "name": "Antarctica",
    "countryCodes": ["672"],
    "isoCodes": ["AQ", "ATA"]
  },
  {
    "name": "Antigua and Barbuda",
    "countryCodes": ["1-268"],
    "isoCodes": ["AG", "ATG"]
  },
  {
    "name": "Argentina",
    "countryCodes": ["54"],
    "isoCodes": ["AR", "ARG"]
  },
  {
    "name": "Armenia",
    "countryCodes": ["374"],
    "isoCodes": ["AM", "ARM"]
  },
  {
    "name": "Aruba",
    "countryCodes": ["297"],
    "isoCodes": ["AW", "ABW"]
  },
  {
    "name": "Australia",
    "countryCodes": ["61"],
    "isoCodes": ["AU", "AUS"]
  },
  {
    "name": "Austria",
    "countryCodes": ["43"],
    "isoCodes": ["AT", "AUT"]
  },
  {
    "name": "Azerbaijan",
    "countryCodes": ["994"],
    "isoCodes": ["AZ", "AZE"]
  },
  {
    "name": "Bahamas",
    "countryCodes": ["1-242"],
    "isoCodes": ["BS", "BHS"]
  },
  {
    "name": "Bahrain",
    "countryCodes": ["973"],
    "isoCodes": ["BH", "BHR"]
  },
  {
    "name": "Bangladesh",
    "countryCodes": ["880"],
    "isoCodes": ["BD", "BGD"]
  },
  {
    "name": "Barbados",
    "countryCodes": ["1-246"],
    "isoCodes": ["BB", "BRB"]
  },
  {
    "name": "Belarus",
    "countryCodes": ["375"],
    "isoCodes": ["BY", "BLR"]
  },
  {
    "name": "Belgium",
    "countryCodes": ["32"],
    "isoCodes": ["BE", "BEL"]
  },
  {
    "name": "Belize",
    "countryCodes": ["501"],
    "isoCodes": ["BZ", "BLZ"]
  },
  {
    "name": "Benin",
    "countryCodes": ["229"],
    "isoCodes": ["BJ", "BEN"]
  },
  {
    "name": "Bermuda",
    "countryCodes": ["1-441"],
    "isoCodes": ["BM", "BMU"]
  },
  {
    "name": "Bhutan",
    "countryCodes": ["975"],
    "isoCodes": ["BT", "BTN"]
  },
  {
    "name": "Bolivia",
    "countryCodes": ["591"],
    "isoCodes": ["BO", "BOL"]
  },
  {
    "name": "Bosnia and Herzegovina",
    "countryCodes": ["387"],
    "isoCodes": ["BA", "BIH"]
  },
  {
    "name": "Botswana",
    "countryCodes": ["267"],
    "isoCodes": ["BW", "BWA"]
  },
  {
    "name": "Brazil",
    "countryCodes": ["55"],
    "isoCodes": ["BR", "BRA"]
  },
  {
    "name": "British Indian Ocean Territory",
    "countryCodes": ["246"],
    "isoCodes": ["IO", "IOT"]
  },
  {
    "name": "British Virgin Islands",
    "countryCodes": ["1-284"],
    "isoCodes": ["VG", "VGB"]
  },
  {
    "name": "Brunei",
    "countryCodes": ["673"],
    "isoCodes": ["BN", "BRN"]
  },
  {
    "name": "Bulgaria",
    "countryCodes": ["359"],
    "isoCodes": ["BG", "BGR"]
  },
  {
    "name": "Burkina Faso",
    "countryCodes": ["226"],
    "isoCodes": ["BF", "BFA"]
  },
  {
    "name": "Burundi",
    "countryCodes": ["257"],
    "isoCodes": ["BI", "BDI"]
  },
  {
    "name": "Cambodia",
    "countryCodes": ["855"],
    "isoCodes": ["KH", "KHM"]
  },
  {
    "name": "Cameroon",
    "countryCodes": ["237"],
    "isoCodes": ["CM", "CMR"]
  },

  {
    "name": "Cape Verde",
    "countryCodes": ["238"],
    "isoCodes": ["CV", "CPV"]
  },
  {
    "name": "Cayman Islands",
    "countryCodes": ["1-345"],
    "isoCodes": ["KY", "CYM"]
  },
  {
    "name": "Central African Republic",
    "countryCodes": ["236"],
    "isoCodes": ["CF", "CAF"]
  },
  {
    "name": "Chad",
    "countryCodes": ["235"],
    "isoCodes": ["TD", "TCD"]
  },
  {
    "name": "Chile",
    "countryCodes": ["56"],
    "isoCodes": ["CL", "CHL"]
  },
  {
    "name": "China",
    "countryCodes": ["86"],
    "isoCodes": ["CN", "CHN"]
  },
  {
    "name": "Christmas Island",
    "countryCodes": ["61"],
    "isoCodes": ["CX", "CXR"]
  },
  {
    "name": "Cocos Islands",
    "countryCodes": ["61"],
    "isoCodes": ["CC", "CCK"]
  },
  {
    "name": "Colombia",
    "countryCodes": ["57"],
    "isoCodes": ["CO", "COL"]
  },
  {
    "name": "Comoros",
    "countryCodes": ["269"],
    "isoCodes": ["KM", "COM"]
  },
  {
    "name": "Cook Islands",
    "countryCodes": ["682"],
    "isoCodes": ["CK", "COK"]
  },
  {
    "name": "Costa Rica",
    "countryCodes": ["506"],
    "isoCodes": ["CR", "CRI"]
  },
  {
    "name": "Croatia",
    "countryCodes": ["385"],
    "isoCodes": ["HR", "HRV"]
  },
  {
    "name": "Cuba",
    "countryCodes": ["53"],
    "isoCodes": ["CU", "CUB"]
  },
  {
    "name": "Curacao",
    "countryCodes": ["599"],
    "isoCodes": ["CW", "CUW"]
  },
  {
    "name": "Cyprus",
    "countryCodes": ["357"],
    "isoCodes": ["CY", "CYP"]
  },
  {
    "name": "Czech Republic",
    "countryCodes": ["420"],
    "isoCodes": ["CZ", "CZE"]
  },
  {
    "name": "Democratic Republic of the Congo",
    "countryCodes": ["243"],
    "isoCodes": ["CD", "COD"]
  },
  {
    "name": "Denmark",
    "countryCodes": ["45"],
    "isoCodes": ["DK", "DNK"]
  },
  {
    "name": "Djibouti",
    "countryCodes": ["253"],
    "isoCodes": ["DJ", "DJI"]
  },
  {
    "name": "Dominica",
    "countryCodes": ["1-767"],
    "isoCodes": ["DM", "DMA"]
  },
  {
    "name": "Dominican Republic",
    "countryCodes": ["1-809", "1-829", "1-849"],
    "isoCodes": ["DO", "DOM"]
  },
  {
    "name": "East Timor",
    "countryCodes": ["670"],
    "isoCodes": ["TL", "TLS"]
  },
  {
    "name": "Ecuador",
    "countryCodes": ["593"],
    "isoCodes": ["EC", "ECU"]
  },
  {
    "name": "Egypt",
    "countryCodes": ["20"],
    "isoCodes": ["EG", "EGY"]
  },
  {
    "name": "El Salvador",
    "countryCodes": ["503"],
    "isoCodes": ["SV", "SLV"]
  },
  {
    "name": "Equatorial Guinea",
    "countryCodes": ["240"],
    "isoCodes": ["GQ", "GNQ"]
  },
  {
    "name": "Eritrea",
    "countryCodes": ["291"],
    "isoCodes": ["ER", "ERI"]
  },
  {
    "name": "Estonia",
    "countryCodes": ["372"],
    "isoCodes": ["EE", "EST"]
  },
  {
    "name": "Ethiopia",
    "countryCodes": ["251"],
    "isoCodes": ["ET", "ETH"]
  },
  {
    "name": "Falkland Islands",
    "countryCodes": ["500"],
    "isoCodes": ["FK", "FLK"]
  },
  {
    "name": "Faroe Islands",
    "countryCodes": ["298"],
    "isoCodes": ["FO", "FRO"]
  },
  {
    "name": "Fiji",
    "countryCodes": ["679"],
    "isoCodes": ["FJ", "FJI"]
  },
  {
    "name": "Finland",
    "countryCodes": ["358"],
    "isoCodes": ["FI", "FIN"]
  },
  {
    "name": "France",
    "countryCodes": ["33"],
    "isoCodes": ["FR", "FRA"]
  },
  {
    "name": "French Polynesia",
    "countryCodes": ["689"],
    "isoCodes": ["PF", "PYF"]
  },
  {
    "name": "Gabon",
    "countryCodes": ["241"],
    "isoCodes": ["GA", "GAB"]
  },
  {
    "name": "Gambia",
    "countryCodes": ["220"],
    "isoCodes": ["GM", "GMB"]
  },
  {
    "name": "Georgia",
    "countryCodes": ["995"],
    "isoCodes": ["GE", "GEO"]
  },
  {
    "name": "Germany",
    "countryCodes": ["49"],
    "isoCodes": ["DE", "DEU"]
  },
  {
    "name": "Ghana",
    "countryCodes": ["233"],
    "isoCodes": ["GH", "GHA"]
  },
  {
    "name": "Gibraltar",
    "countryCodes": ["350"],
    "isoCodes": ["GI", "GIB"]
  },
  {
    "name": "Greece",
    "countryCodes": ["30"],
    "isoCodes": ["GR", "GRC"]
  },
  {
    "name": "Greenland",
    "countryCodes": ["299"],
    "isoCodes": ["GL", "GRL"]
  },
  {
    "name": "Grenada",
    "countryCodes": ["1-473"],
    "isoCodes": ["GD", "GRD"]
  },
  {
    "name": "Guam",
    "countryCodes": ["1-671"],
    "isoCodes": ["GU", "GUM"]
  },
  {
    "name": "Guatemala",
    "countryCodes": ["502"],
    "isoCodes": ["GT", "GTM"]
  },
  {
    "name": "Guernsey",
    "countryCodes": ["44-1481"],
    "isoCodes": ["GG", "GGY"]
  },
  {
    "name": "Guinea",
    "countryCodes": ["224"],
    "isoCodes": ["GN", "GIN"]
  },
  {
    "name": "Guinea-Bissau",
    "countryCodes": ["245"],
    "isoCodes": ["GW", "GNB"]
  },
  {
    "name": "Guyana",
    "countryCodes": ["592"],
    "isoCodes": ["GY", "GUY"]
  },
  {
    "name": "Haiti",
    "countryCodes": ["509"],
    "isoCodes": ["HT", "HTI"]
  },
  {
    "name": "Honduras",
    "countryCodes": ["504"],
    "isoCodes": ["HN", "HND"]
  },
  {
    "name": "Hong Kong",
    "countryCodes": ["852"],
    "isoCodes": ["HK", "HKG"]
  },
  {
    "name": "Hungary",
    "countryCodes": ["36"],
    "isoCodes": ["HU", "HUN"]
  },
  {
    "name": "Iceland",
    "countryCodes": ["354"],
    "isoCodes": ["IS", "ISL"]
  },
  {
    "name": "India",
    "countryCodes": ["91"],
    "isoCodes": ["IN", "IND"]
  },
  {
    "name": "Indonesia",
    "countryCodes": ["62"],
    "isoCodes": ["ID", "IDN"]
  },
  {
    "name": "Iran",
    "countryCodes": ["98"],
    "isoCodes": ["IR", "IRN"]
  },
  {
    "name": "Iraq",
    "countryCodes": ["964"],
    "isoCodes": ["IQ", "IRQ"]
  },
  {
    "name": "Ireland",
    "countryCodes": ["353"],
    "isoCodes": ["IE", "IRL"]
  },
  {
    "name": "Isle of Man",
    "countryCodes": ["44-1624"],
    "isoCodes": ["IM", "IMN"]
  },
  {
    "name": "Israel",
    "countryCodes": ["972"],
    "isoCodes": ["IL", "ISR"]
  },
  {
    "name": "Italy",
    "countryCodes": ["39"],
    "isoCodes": ["IT", "ITA"]
  },
  {
    "name": "Ivory Coast",
    "countryCodes": ["225"],
    "isoCodes": ["CI", "CIV"]
  },
  {
    "name": "Jamaica",
    "countryCodes": ["1-876"],
    "isoCodes": ["JM", "JAM"]
  },
  {
    "name": "Japan",
    "countryCodes": ["81"],
    "isoCodes": ["JP", "JPN"]
  },
  {
    "name": "Jersey",
    "countryCodes": ["44-1534"],
    "isoCodes": ["JE", "JEY"]
  },
  {
    "name": "Jordan",
    "countryCodes": ["962"],
    "isoCodes": ["JO", "JOR"]
  },
  {
    "name": "Kazakhstan",
    "countryCodes": ["7"],
    "isoCodes": ["KZ", "KAZ"]
  },
  {
    "name": "Kenya",
    "countryCodes": ["254"],
    "isoCodes": ["KE", "KEN"]
  },
  {
    "name": "Kiribati",
    "countryCodes": ["686"],
    "isoCodes": ["KI", "KIR"]
  },
  {
    "name": "Kosovo",
    "countryCodes": ["383"],
    "isoCodes": ["XK", "XKX"]
  },
  {
    "name": "Kuwait",
    "countryCodes": ["965"],
    "isoCodes": ["KW", "KWT"]
  },
  {
    "name": "Kyrgyzstan",
    "countryCodes": ["996"],
    "isoCodes": ["KG", "KGZ"]
  },
  {
    "name": "Laos",
    "countryCodes": ["856"],
    "isoCodes": ["LA", "LAO"]
  },
  {
    "name": "Latvia",
    "countryCodes": ["371"],
    "isoCodes": ["LV", "LVA"]
  },
  {
    "name": "Lebanon",
    "countryCodes": ["961"],
    "isoCodes": ["LB", "LBN"]
  },
  {
    "name": "Lesotho",
    "countryCodes": ["266"],
    "isoCodes": ["LS", "LSO"]
  },
  {
    "name": "Liberia",
    "countryCodes": ["231"],
    "isoCodes": ["LR", "LBR"]
  },
  {
    "name": "Libya",
    "countryCodes": ["218"],
    "isoCodes": ["LY", "LBY"]
  },
  {
    "name": "Liechtenstein",
    "countryCodes": ["423"],
    "isoCodes": ["LI", "LIE"]
  },
  {
    "name": "Lithuania",
    "countryCodes": ["370"],
    "isoCodes": ["LT", "LTU"]
  },
  {
    "name": "Luxembourg",
    "countryCodes": ["352"],
    "isoCodes": ["LU", "LUX"]
  },
  {
    "name": "Macau",
    "countryCodes": ["853"],
    "isoCodes": ["MO", "MAC"]
  },
  {
    "name": "Macedonia",
    "countryCodes": ["389"],
    "isoCodes": ["MK", "MKD"]
  },
  {
    "name": "Madagascar",
    "countryCodes": ["261"],
    "isoCodes": ["MG", "MDG"]
  },
  {
    "name": "Malawi",
    "countryCodes": ["265"],
    "isoCodes": ["MW", "MWI"]
  },
  {
    "name": "Malaysia",
    "countryCodes": ["60"],
    "isoCodes": ["MY", "MYS"]
  },
  {
    "name": "Maldives",
    "countryCodes": ["960"],
    "isoCodes": ["MV", "MDV"]
  },
  {
    "name": "Mali",
    "countryCodes": ["223"],
    "isoCodes": ["ML", "MLI"]
  },
  {
    "name": "Malta",
    "countryCodes": ["356"],
    "isoCodes": ["MT", "MLT"]
  },
  {
    "name": "Marshall Islands",
    "countryCodes": ["692"],
    "isoCodes": ["MH", "MHL"]
  },
  {
    "name": "Mauritania",
    "countryCodes": ["222"],
    "isoCodes": ["MR", "MRT"]
  },
  {
    "name": "Mauritius",
    "countryCodes": ["230"],
    "isoCodes": ["MU", "MUS"]
  },
  {
    "name": "Mayotte",
    "countryCodes": ["262"],
    "isoCodes": ["YT", "MYT"]
  },
  {
    "name": "Mexico",
    "countryCodes": ["52"],
    "isoCodes": ["MX", "MEX"]
  },
  {
    "name": "Micronesia",
    "countryCodes": ["691"],
    "isoCodes": ["FM", "FSM"]
  },
  {
    "name": "Moldova",
    "countryCodes": ["373"],
    "isoCodes": ["MD", "MDA"]
  },
  {
    "name": "Monaco",
    "countryCodes": ["377"],
    "isoCodes": ["MC", "MCO"]
  },
  {
    "name": "Mongolia",
    "countryCodes": ["976"],
    "isoCodes": ["MN", "MNG"]
  },
  {
    "name": "Montenegro",
    "countryCodes": ["382"],
    "isoCodes": ["ME", "MNE"]
  },
  {
    "name": "Montserrat",
    "countryCodes": ["1-664"],
    "isoCodes": ["MS", "MSR"]
  },
  {
    "name": "Morocco",
    "countryCodes": ["212"],
    "isoCodes": ["MA", "MAR"]
  },
  {
    "name": "Mozambique",
    "countryCodes": ["258"],
    "isoCodes": ["MZ", "MOZ"]
  },
  {
    "name": "Myanmar",
    "countryCodes": ["95"],
    "isoCodes": ["MN", "MMR"]
  },
  {
    "name": "Namibia",
    "countryCodes": ["264"],
    "isoCodes": ["NA", "NAM"]
  },
  {
    "name": "Nauru",
    "countryCodes": ["674"],
    "isoCodes": ["NR", "NRU"]
  },
  {
    "name": "Nepal",
    "countryCodes": ["977"],
    "isoCodes": ["NP", "NPL"]
  },
  {
    "name": "Netherlands",
    "countryCodes": ["31"],
    "isoCodes": ["NL", "NLD"]
  },
  {
    "name": "Netherlands Antilles",
    "countryCodes": ["599"],
    "isoCodes": ["AN", "ANT"]
  },
  {
    "name": "New Caledonia",
    "countryCodes": ["687"],
    "isoCodes": ["NC", "NCL"]
  },
  {
    "name": "New Zealand",
    "countryCodes": ["64"],
    "isoCodes": ["NZ", "NZL"]
  },
  {
    "name": "Nicaragua",
    "countryCodes": ["505"],
    "isoCodes": ["NI", "NIC"]
  },
  {
    "name": "Niger",
    "countryCodes": ["227"],
    "isoCodes": ["NE", "NER"]
  },
  {
    "name": "Nigeria",
    "countryCodes": ["234"],
    "isoCodes": ["NG", "NGA"]
  },
  {
    "name": "Niue",
    "countryCodes": ["683"],
    "isoCodes": ["NU", "NIU"]
  },
  {
    "name": "North Korea",
    "countryCodes": ["850"],
    "isoCodes": ["KP", "PRK"]
  },
  {
    "name": "Northern Mariana Islands",
    "countryCodes": ["1-670"],
    "isoCodes": ["MP", "MNP"]
  },
  {
    "name": "Norway",
    "countryCodes": ["47"],
    "isoCodes": ["NO", "NOR"]
  },
  {
    "name": "Oman",
    "countryCodes": ["968"],
    "isoCodes": ["OM", "OMN"]
  },
  {
    "name": "Pakistan",
    "countryCodes": ["92"],
    "isoCodes": ["PK", "PAK"]
  },
  {
    "name": "Palau",
    "countryCodes": ["680"],
    "isoCodes": ["PW", "PLW"]
  },
  {
    "name": "Palestine",
    "countryCodes": ["970"],
    "isoCodes": ["PS", "PSE"]
  },
  {
    "name": "Panama",
    "countryCodes": ["507"],
    "isoCodes": ["PA", "PAN"]
  },
  {
    "name": "Papua New Guinea",
    "countryCodes": ["675"],
    "isoCodes": ["PG", "PNG"]
  },
  {
    "name": "Paraguay",
    "countryCodes": ["595"],
    "isoCodes": ["PY", "PRY"]
  },
  {
    "name": "Peru",
    "countryCodes": ["51"],
    "isoCodes": ["PE", "PER"]
  },
  {
    "name": "Philippines",
    "countryCodes": ["63"],
    "isoCodes": ["PH", "PHL"]
  },
  {
    "name": "Pitcairn",
    "countryCodes": ["64"],
    "isoCodes": ["PN", "PCN"]
  },
  {
    "name": "Poland",
    "countryCodes": ["48"],
    "isoCodes": ["PL", "POL"]
  },
  {
    "name": "Portugal",
    "countryCodes": ["351"],
    "isoCodes": ["PT", "PRT"]
  },
  {
    "name": "Puerto Rico",
    "countryCodes": ["1-787", "1-939"],
    "isoCodes": ["PR", "PRI"]
  },
  {
    "name": "Qatar",
    "countryCodes": ["974"],
    "isoCodes": ["QA", "QAT"]
  },
  {
    "name": "Republic of the Congo",
    "countryCodes": ["242"],
    "isoCodes": ["CG", "COG"]
  },
  {
    "name": "Reunion",
    "countryCodes": ["262"],
    "isoCodes": ["RE", "REU"]
  },
  {
    "name": "Romania",
    "countryCodes": ["40"],
    "isoCodes": ["RO", "ROU"]
  },
  {
    "name": "Russia",
    "countryCodes": ["7"],
    "isoCodes": ["RU", "RUS"]
  },
  {
    "name": "Rwanda",
    "countryCodes": ["250"],
    "isoCodes": ["RW", "RWA"]
  },
  {
    "name": "Saint Barthelemy",
    "countryCodes": ["590"],
    "isoCodes": ["BL", "BLM"]
  },
  {
    "name": "Saint Helena",
    "countryCodes": ["290"],
    "isoCodes": ["SH", "SHN"]
  },
  {
    "name": "Saint Kitts and Nevis",
    "countryCodes": ["1-869"],
    "isoCodes": ["KN", "KNA"]
  },
  {
    "name": "Saint Lucia",
    "countryCodes": ["1-758"],
    "isoCodes": ["LC", "LCA"]
  },
  {
    "name": "Saint Martin",
    "countryCodes": ["590"],
    "isoCodes": ["MF", "MAF"]
  },
  {
    "name": "Saint Pierre and Miquelon",
    "countryCodes": ["508"],
    "isoCodes": ["PM", "SPM"]
  },
  {
    "name": "Saint Vincent and the Grenadines",
    "countryCodes": ["1-784"],
    "isoCodes": ["VC", "VCT"]
  },
  {
    "name": "Samoa",
    "countryCodes": ["685"],
    "isoCodes": ["WS", "WSM"]
  },
  {
    "name": "San Marino",
    "countryCodes": ["378"],
    "isoCodes": ["SM", "SMR"]
  },
  {
    "name": "Sao Tome and Principe",
    "countryCodes": ["239"],
    "isoCodes": ["ST", "STP"]
  },
  {
    "name": "Saudi Arabia",
    "countryCodes": ["966"],
    "isoCodes": ["SA", "SAU"]
  },
  {
    "name": "Senegal",
    "countryCodes": ["221"],
    "isoCodes": ["SN", "SEN"]
  },
  {
    "name": "Serbia",
    "countryCodes": ["381"],
    "isoCodes": ["RS", "SRB"]
  },
  {
    "name": "Seychelles",
    "countryCodes": ["248"],
    "isoCodes": ["SC", "SYC"]
  },
  {
    "name": "Sierra Leone",
    "countryCodes": ["232"],
    "isoCodes": ["SL", "SLE"]
  },
  {
    "name": "Singapore",
    "countryCodes": ["65"],
    "isoCodes": ["SG", "SGP"]
  },
  {
    "name": "Sint Maarten",
    "countryCodes": ["1-721"],
    "isoCodes": ["SX", "SXM"]
  },
  {
    "name": "Slovakia",
    "countryCodes": ["421"],
    "isoCodes": ["SK", "SVK"]
  },
  {
    "name": "Slovenia",
    "countryCodes": ["386"],
    "isoCodes": ["SI", "SVN"]
  },
  {
    "name": "Solomon Islands",
    "countryCodes": ["677"],
    "isoCodes": ["SB", "SLB"]
  },
  {
    "name": "Somalia",
    "countryCodes": ["252"],
    "isoCodes": ["SO", "SOM"]
  },
  {
    "name": "South Africa",
    "countryCodes": ["27"],
    "isoCodes": ["ZA", "ZAF"]
  },
  {
    "name": "South Korea",
    "countryCodes": ["82"],
    "isoCodes": ["KR", "KOR"]
  },
  {
    "name": "South Sudan",
    "countryCodes": ["211"],
    "isoCodes": ["SS", "SSD"]
  },
  {
    "name": "Spain",
    "countryCodes": ["34"],
    "isoCodes": ["ES", "ESP"]
  },
  {
    "name": "Sri Lanka",
    "countryCodes": ["94"],
    "isoCodes": ["LK", "LKA"]
  },
  {
    "name": "Sudan",
    "countryCodes": ["249"],
    "isoCodes": ["SD", "SDN"]
  },
  {
    "name": "Suriname",
    "countryCodes": ["597"],
    "isoCodes": ["SR", "SUR"]
  },
  {
    "name": "Svalbard and Jan Mayen",
    "countryCodes": ["47"],
    "isoCodes": ["SJ", "SJM"]
  },
  {
    "name": "Swaziland",
    "countryCodes": ["268"],
    "isoCodes": ["SZ", "SWZ"]
  },
  {
    "name": "Sweden",
    "countryCodes": ["46"],
    "isoCodes": ["SE", "SWE"]
  },
  {
    "name": "Switzerland",
    "countryCodes": ["41"],
    "isoCodes": ["CH", "CHE"]
  },
  {
    "name": "Syria",
    "countryCodes": ["963"],
    "isoCodes": ["SY", "SYR"]
  },
  {
    "name": "Taiwan",
    "countryCodes": ["886"],
    "isoCodes": ["TW", "TWN"]
  },
  {
    "name": "Tajikistan",
    "countryCodes": ["992"],
    "isoCodes": ["TJ", "TJK"]
  },
  {
    "name": "Tanzania",
    "countryCodes": ["255"],
    "isoCodes": ["TZ", "TZA"]
  },
  {
    "name": "Thailand",
    "countryCodes": ["66"],
    "isoCodes": ["TH", "THA"]
  },
  {
    "name": "Togo",
    "countryCodes": ["228"],
    "isoCodes": ["TG", "TGO"]
  },
  {
    "name": "Tokelau",
    "countryCodes": ["690"],
    "isoCodes": ["TK", "TKL"]
  },
  {
    "name": "Tonga",
    "countryCodes": ["676"],
    "isoCodes": ["TO", "TON"]
  },
  {
    "name": "Trinidad and Tobago",
    "countryCodes": ["1-868"],
    "isoCodes": ["TT", "TTO"]
  },
  {
    "name": "Tunisia",
    "countryCodes": ["216"],
    "isoCodes": ["TN", "TUN"]
  },
  {
    "name": "Turkey",
    "countryCodes": ["90"],
    "isoCodes": ["TR", "TUR"]
  },
  {
    "name": "Turkmenistan",
    "countryCodes": ["993"],
    "isoCodes": ["TM", "TKM"]
  },
  {
    "name": "Turks and Caicos Islands",
    "countryCodes": ["1-649"],
    "isoCodes": ["TC", "TCA"]
  },
  {
    "name": "Tuvalu",
    "countryCodes": ["688"],
    "isoCodes": ["TV", "TUV"]
  },
  {
    "name": "U.S. Virgin Islands",
    "countryCodes": ["1-340"],
    "isoCodes": ["VI", "VIR"]
  },
  {
    "name": "Uganda",
    "countryCodes": ["256"],
    "isoCodes": ["UG", "UGA"]
  },
  {
    "name": "Ukraine",
    "countryCodes": ["380"],
    "isoCodes": ["UA", "UKR"]
  },
  {
    "name": "United Arab Emirates",
    "countryCodes": ["971"],
    "isoCodes": ["AE", "ARE"]
  },
  {
    "name": "United Kingdom",
    "countryCodes": ["44"],
    "isoCodes": ["GB", "GBR"]
  },
  {
    "name": "Uruguay",
    "countryCodes": ["598"],
    "isoCodes": ["UY", "URY"]
  },
  {
    "name": "Uzbekistan",
    "countryCodes": ["998"],
    "isoCodes": ["UZ", "UZB"]
  },
  {
    "name": "Vanuatu",
    "countryCodes": ["678"],
    "isoCodes": ["VU", "VUT"]
  },
  {
    "name": "Vatican",
    "countryCodes": ["379"],
    "isoCodes": ["VA", "VAT"]
  },
  {
    "name": "Venezuela",
    "countryCodes": ["58"],
    "isoCodes": ["VE", "VEN"]
  },
  {
    "name": "Vietnam",
    "countryCodes": ["84"],
    "isoCodes": ["VN", "VNM"]
  },
  {
    "name": "Wallis and Futuna",
    "countryCodes": ["681"],
    "isoCodes": ["WF", "WLF"]
  },
  {
    "name": "Western Sahara",
    "countryCodes": ["212"],
    "isoCodes": ["EH", "ESH"]
  },
  {
    "name": "Yemen",
    "countryCodes": ["967"],
    "isoCodes": ["YE", "YEM"]
  },
  {
    "name": "Zambia",
    "countryCodes": ["260"],
    "isoCodes": ["ZM", "ZMB"]
  },
  {
    "name": "Zimbabwe",
    "countryCodes": ["263"],
    "isoCodes": ["ZW", "ZWE"]
  }
]
