import { Component, EventEmitter, Input, input, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgClass } from '@angular/common';
import { FormUtils } from '../utils/form-utils';
import UsStates from './states.json';

interface UsState {
  name: string;
  abbreviation: string;
}

export interface Address {
  address1: string;
  address2?: string;
  city: string;
  postalCode: string;
  state: string;
}

interface AddressForm {
  address1: FormControl<string>;
  address2: FormControl<string | undefined>;
  city: FormControl<string>;
  state: FormControl<string>;
  postalCode: FormControl<string>;
}

export function createAddressForm(address?: Address): FormGroup<AddressForm> {
  return new FormGroup<AddressForm>({
    address1: new FormControl(address?.address1 ?? '', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(128)],
    }),
    address2: new FormControl(address?.address2 ?? '', {
      nonNullable: true,
      validators: [Validators.maxLength(128)],
    }),
    city: new FormControl(address?.city ?? '', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(64)],
    }),
    state: new FormControl(address?.state ?? '', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(64)],
    }),
    postalCode: new FormControl(address?.postalCode ?? '', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(24)],
    }),
  });
}

@Component({
  selector: 'app-address',
  standalone: true,
  imports: [ReactiveFormsModule, NgClass],
  templateUrl: './address.component.html',
})
export class AddressComponent {
  @Input() addressForm!: FormGroup<AddressForm>;
  saveText = input('save');

  @Output() save = new EventEmitter<Address>();

  readonly usStates: UsState[] = UsStates as UsState[];
  readonly formUtils = FormUtils;

  constructor(private fb: FormBuilder) {}

  updateAddressForm(address?: Address): void {
    this.addressForm.patchValue({
      address1: address?.address1 ?? '',
      address2: address?.address2 ?? '',
      city: address?.city ?? '',
      postalCode: address?.postalCode ?? '',
      state: address?.state ?? '',
    });
  }

  onSave(): void {
    if (this.addressForm.invalid) {
      return this.addressForm.markAllAsTouched();
    }

    this.save.emit({
      address1: this.addressForm.controls.address1.value,
      address2: this.addressForm.controls.address2.value,
      city: this.addressForm.controls.city.value,
      postalCode: this.addressForm.controls.postalCode.value,
      state: this.addressForm.controls.state.value,
    });
  }
}
