/*
 * Created by Kijana J. James on 7/14/23, 10:59 AM.
 * Keejware LLC, All Rights Reserved
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SnackBarAlertService } from './snack-bar-alert.service';

const defaultDuration = 3000;

@Component({
  selector: 'app-snack-bar-alert',
  standalone: true,
  imports: [CommonModule, MatSnackBarModule],
  template: '',
})
export class SnackBarAlertComponent implements OnInit, OnDestroy {
  onDestroy$ = new Subject<void>();

  constructor(
    private matSnackBar: MatSnackBar,
    private snackBarAlertService: SnackBarAlertService,
  ) {}

  ngOnInit(): void {
    this.snackBarAlertService.alert$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((alert) =>
        this.matSnackBar.open(alert.message, alert.action || 'dismiss', {
          duration: alert.durationInMilliseconds || defaultDuration,
        }),
      );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();
  }
}
