/*
 * Created by Kijana J. James on 7/8/23, 6:07 PM.
 * Keejware LLC, All Rights Reserved
 */

import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { ProgressBarService } from './progress-bar.service';

@Component({
  selector: 'app-progress-bar',
  standalone: true,
  imports: [CommonModule],
  styleUrl: './progress-bar.component.sass',
  templateUrl: './progress-bar.component.html',
})
export class ProgressBarComponent implements OnInit {
  @Input() showProgress = false;
  inProgress$!: Observable<boolean>;

  constructor(private progressBarService: ProgressBarService) {}

  ngOnInit(): void {
    this.inProgress$ = this.progressBarService.inProgress$;
  }
}
