/*
 * Created by Kijana J. James on 3/7/23, 3:25 PM.
 * Keejware LLC, All Rights Reserved
 */
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { version } from '../../../package.json';

@Component({
  selector: 'app-footer-bar',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.sass'],
})
export class FooterBarComponent {
  year = new Date().getFullYear();
  version = version;
}
