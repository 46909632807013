<mat-form-field class="w-100 fs-4" appearance="outline">
  <mat-date-range-input
    [formGroup]="dateRangeForm"
    [rangePicker]="recentActivityDatePicker"
    [comparisonStart]="dateRangeForm.value.fromDate"
    [comparisonEnd]="dateRangeForm.value.toDate"
  >
    <input matStartDate placeholder="from" formControlName="fromDate" />
    <input matEndDate placeholder="to" formControlName="toDate" />
  </mat-date-range-input>
  <mat-hint>
    {{ dateRangeForm.value.fromDate | date: 'EE, MMM d, y' }}
    --
    {{ dateRangeForm.value.toDate | date: 'EE, MMM d, y' }}
  </mat-hint>
  <mat-datepicker-toggle
    matIconSuffix
    [for]="recentActivityDatePicker"
  ></mat-datepicker-toggle>
  <mat-date-range-picker #recentActivityDatePicker></mat-date-range-picker>
</mat-form-field>
