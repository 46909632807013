<div class="row">
  <div class="col-4">
    <div class="form-floating">
      <select
        class="form-select"
        id="countryCode"
        aria-label="country code"
        [formControl]="countryCodeControl"
      >
        @for (countryCode of countryCodes; track countryCode) {
          <option [value]="countryCode.code">
            {{ countryCode.code }} {{ countryCode.country }}
          </option>
        }
      </select>
      <label for="countryCode">country</label>
    </div>
  </div>
  <div class="col-8">
    <div class="form-group">
      <div
        class="form-floating"
        [ngClass]="{
          'is-invalid': formUtils.controlHasError(phoneControl)
        }"
      >
        <input
          type="tel"
          class="form-control"
          id="phoneNumber"
          placeholder="phone number"
          aria-label="phone number"
          maxlength="10"
          [formControl]="phoneControl"
          [ngClass]="{
            'is-invalid': formUtils.controlHasError(phoneControl)
          }"
        />
        <label for="phoneNumber">phone</label>
      </div>
      @if (formUtils.controlHasError(phoneControl, 'required')) {
        <span class="invalid-feedback"> required </span>
      }

      @if (formUtils.controlHasError(phoneControl, 'pattern')) {
        <span class="invalid-feedback"> numbers only </span>
      }

      @if (formUtils.controlHasError(phoneControl, serverError)) {
        <span class="invalid-feedback">
          {{ phoneControl.touched && phoneControl.errors?.[serverError] }}
        </span>
      }
    </div>
  </div>
</div>
