/*
 * Created by Kijana J. James on 7/8/23, 8:30 PM.
 * Keejware LLC, All Rights Reserved
 */

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ProgressBarService {
  inProgress$ = new Subject<boolean>();

  inProgress(inProgress: boolean): void {
    this.inProgress$.next(inProgress);
  }
}

export class ProgressBarServiceTestStub {
  inProgress(inProgress: boolean): void {
    console.log('inProgress', inProgress);
  }
}
