/*
 * Created by Kijana J. James on 7/14/23, 1:38 PM.
 * Keejware LLC, All Rights Reserved
 */

import { Component, HostListener, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-online-check',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './online-check.component.html',
})
export class OnlineCheckComponent implements OnInit {
  isOnline = false;

  ngOnInit(): void {
    this.isOnline = window.navigator?.onLine;
  }

  @HostListener('window:online')
  online(): void {
    this.isOnline = true;
  }

  @HostListener('window:offline')
  offline(): void {
    this.isOnline = false;
  }
}
