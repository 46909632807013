@if (startScan()) {
  <div class="scanner-container d-flex flex-column align-items-center">
    <button
      id="close-camera-button"
      type="button"
      class="mt-1 me-2 btn btn-danger rounded-5 align-self-end"
      (click)="onClose()"
    >
      <i class="bi bi-x-lg fw-bolder"></i>
    </button>
    <div class="d-flex flex-column align-items-center">
      <video
        #scanner
        class="mt-1 border border-3 border-primary rounded"
      ></video>
    </div>
    <p class="fs-1">scan qr code</p>
  </div>
} @else {
  <ng-content></ng-content>
}
