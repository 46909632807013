<!--
  ~ Created by Kijana J. James on 7/8/23, 6:06 PM.
  ~ Keejware LLC, All Rights Reserved
  -->
<div
  class="progress-bar"
  [ngStyle]="{
    visibility: (inProgress$ | async) || showProgress ? 'visible' : 'hidden'
  }"
>
  <div class="progress-bar-value" role="progressbar"></div>
</div>
