{
  "name": "zlaed-ng",
  "version": "0.5.1",
  "scripts": {
    "ng": "ng",
    "clean": "rimraf dist",
    "start": "ng serve --host 0.0.0.0 --configuration local --ssl --ssl-cert \"ssl/localhost.pem\" --ssl-key \"ssl/localhost-key.pem\"",
    "start-lan": "ng serve --host 192.168.4.23 --configuration local --ssl --ssl-cert \"ssl/localhost.pem\" --ssl-key \"ssl/localhost-key.pem\"",
    "build": "npm run clean && ng build",
    "build:dev": "npm run clean && ng build --configuration development",
    "watch": "ng build --watch --configuration local",
    "prepare": "husky install",
    "test": "jest --verbose",
    "lint": "eslint . --ext .ts",
    "prettier": "prettier --write ./src --ignore-unknown"
  },
  "lint-staged": {
    "**/*": "prettier --write ./src --ignore-unknown"
  },
  "jest": {
    "preset": "jest-preset-angular",
    "setupFilesAfterEnv": [
      "<rootDir>/setup-jest.ts"
    ],
    "modulePaths": [
      "<rootDir>"
    ]
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.1.3",
    "@angular/cdk": "^19.1.1",
    "@angular/common": "^19.1.3",
    "@angular/compiler": "^19.1.3",
    "@angular/core": "^19.1.3",
    "@angular/forms": "^19.1.3",
    "@angular/material": "^19.1.1",
    "@angular/platform-browser": "^19.1.3",
    "@angular/platform-browser-dynamic": "^19.1.3",
    "@angular/router": "^19.1.3",
    "@angular/service-worker": "^19.1.3",
    "@ng-bootstrap/ng-bootstrap": "^18.0.0",
    "@ngrx/effects": "^19.0.0",
    "@ngrx/operators": "^19.0.0",
    "@ngrx/store": "^19.0.0",
    "@ngrx/store-devtools": "^19.0.0",
    "@popperjs/core": "^2.11.6",
    "bootstrap": "^5.2.3",
    "bootstrap-icons": "^1.10.4",
    "html5-qrcode": "^2.3.8",
    "idb-keyval": "^6.2.0",
    "ngx-kjua": "^18.1.3",
    "ngx-webcam": "^0.4.1",
    "qr-scanner": "^1.4.2",
    "rimraf": "^3.0.2",
    "rxjs": "~7.5.0",
    "tslib": "^2.3.0",
    "uuid": "^9.0.0",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular/build": "^19.1.4",
    "@angular/cli": "~19.1.4",
    "@angular/compiler-cli": "^19.1.3",
    "@angular/localize": "^19.1.3",
    "@types/jasmine": "~4.0.0",
    "@types/jest": "^29.5.14",
    "@types/lodash-es": "^4.17.12",
    "@types/lodash.sortby": "^4.7.7",
    "@types/uuid": "^8.3.4",
    "@typescript-eslint/eslint-plugin": "^5.60.0",
    "@typescript-eslint/parser": "^5.60.0",
    "eslint": "^8.44.0",
    "eslint-config-prettier": "^8.8.0",
    "eslint-plugin-html": "^7.1.0",
    "eslint-plugin-unused-imports": "^2.0.0",
    "husky": "^8.0.3",
    "jest": "^29.7.0",
    "jest-preset-angular": "^14.2.4",
    "ng-packagr": "^19.1.1",
    "prettier": "3.2.5",
    "typescript": "^5.5.4"
  },
  "engines": {
    "node": ">=18.0.0"
  }
}
