/*
 * Created by Kijana J. James on 12/18/22, 2:09 PM.
 * Keejware LLC, All Rights Reserved
 */
import { Injectable } from '@angular/core';

const LOCAL_STORAGE_NAMESPACE = 'zlaed::';

export enum DeviceOs {
  iOS = 'iOS',
  android = 'android',
  other = 'other',
}

@Injectable()
export class WindowService {
  setItemInLocalStorage(
    key: string,
    value: string,
    namespace = LOCAL_STORAGE_NAMESPACE,
  ): void {
    window.localStorage.setItem(`${namespace}${key}`, value);
  }

  setItemInSessionStorage(
    key: string,
    value: string,
    namespace = LOCAL_STORAGE_NAMESPACE,
  ): void {
    window.sessionStorage.setItem(`${namespace}${key}`, value);
  }

  getItemFromLocalStorage(
    key: string,
    namespace = LOCAL_STORAGE_NAMESPACE,
  ): string | null {
    return window.localStorage.getItem(`${namespace}${key}`);
  }

  getItemFromSessionStorage(
    key: string,
    namespace = LOCAL_STORAGE_NAMESPACE,
  ): string | null {
    return window.sessionStorage.getItem(`${namespace}${key}`);
  }

  removeItemInLocalStorage(
    key: string,
    namespace = LOCAL_STORAGE_NAMESPACE,
  ): void {
    window.localStorage.removeItem(`${namespace}${key}`);
  }

  setTimeout(handler: TimerHandler, timeout?: number): number {
    return window.setTimeout(handler, timeout);
  }

  clearTimeout(id: number): void {
    window.clearTimeout(id);
  }

  getDeviceOs(): DeviceOs {
    const iosUserAgents = ['iphone', 'ipad', 'mac_os_x'];
    const { userAgent = '' } = window.navigator || {};

    if (iosUserAgents.includes(userAgent?.toLowerCase())) {
      return DeviceOs.iOS;
    } else if (userAgent?.toLowerCase().includes('android')) {
      return DeviceOs.android;
    }

    return DeviceOs.other;
  }

  clearLocalStorage(): void {
    window.localStorage.clear();
  }

  getNavigator(): Navigator {
    return window.navigator;
  }

  getDocument(): Document {
    return window.document;
  }

  getLocation(): Location {
    return window.location;
  }
}

export class WindowsServiceStub {
  setItemInLocalStorage(
    key: string,
    value: string,
    namespace = LOCAL_STORAGE_NAMESPACE,
  ): void {
    console.log('setItemInLocalStorage', key, value, namespace);
  }

  setItemInSessionStorage(
    key: string,
    value: string,
    namespace = LOCAL_STORAGE_NAMESPACE,
  ): void {
    console.log('setItemInSessionStorage', key, value, namespace);
  }

  getItemFromLocalStorage(
    key: string,
    namespace = LOCAL_STORAGE_NAMESPACE,
  ): string | null {
    console.log('getItemFromLocalStorage', key, namespace);
    return null;
  }

  getItemFromSessionStorage(
    key: string,
    namespace = LOCAL_STORAGE_NAMESPACE,
  ): string | null {
    console.log('getItemFromSessionStorage', key, namespace);
    return null;
  }

  removeItemInLocalStorage(
    key: string,
    namespace = LOCAL_STORAGE_NAMESPACE,
  ): void {
    console.log('removeItemInLocalStorage', key, namespace);
  }

  removeItemInSessionStorage(
    key: string,
    namespace = LOCAL_STORAGE_NAMESPACE,
  ): void {
    console.log('removeItemInSessionStorage', key, namespace);
  }

  setTimeout(handler: TimerHandler, timeout?: number): number {
    console.log('setTimeout', handler, timeout);
    return 0;
  }

  clearTimeout(id: number): void {
    console.log('clearTimeout', id);
  }

  getDeviceOs(): DeviceOs {
    return DeviceOs.other;
  }

  getNavigator(): any {
    return {
      mediaDevices: {
        getUserMedia(params: any): Promise<void> {
          return Promise.resolve();
        },
      },
    };
  }

  getDocument(): any {
    return {
      querySelectorAll() {
        console.log('querySelectorAll called');
      },
    };
  }

  getLocation(): Location {
    return {} as Location;
  }
}
