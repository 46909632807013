@if (url) {
  <button
    class="action-btn"
    type="button"
    mat-fab
    color="primary"
    [attr.aria-label]="ariaLabel"
    [routerLink]="url"
  >
    <mat-icon>{{ icon }}</mat-icon>
  </button>
} @else {
  <button
    class="action-btn"
    type="button"
    mat-fab
    color="primary"
    [attr.aria-label]="ariaLabel"
    (click)="handleClick()"
  >
    <mat-icon>{{ icon }}</mat-icon>
  </button>
}
