<!--
  ~ Created by Kijana J. James on 11/22/23, 12:13 PM.
  ~ Keejware LLC, All Rights Reserved
  -->
<div class="container form-container mfa-container mt-3 mb-3">
  @if (!started) {
    <form [formGroup]="phoneForm" (ngSubmit)="onStart()" autocomplete="off">
      <div class="form-group">
        <div class="row mb-3">
          <div class="col-12">
            <app-phone-number
              [countryCode]="countryCode"
              [countryCodeControl]="phoneForm.controls.countryCode"
              [phone]="phone"
              [phoneControl]="phoneForm.controls.phone"
            ></app-phone-number>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button
              type="submit"
              [disabled]="submitting"
              class="btn btn-primary w-100"
            >
              {{ startText }}
            </button>
          </div>
        </div>
      </div>
    </form>
  } @else {
    <div class="row">
      <p class="col-12 mb-3 fw-lighter">
        a verification code has been sent to phone XXX-XXX-<span
          class="fw-bolder"
          >{{ last4 }}</span
        >
      </p>
      <div class="col-12 mb-3">
        <div class="form-group">
          <div
            class="form-floating"
            [ngClass]="{
              'is-invalid': formUtils.controlHasError(verificationCode)
            }"
          >
            <input
              type="text"
              [formControl]="verificationCode"
              (keyup.Enter)="onVerify()"
              id="verificationCode"
              class="form-control"
              placeholder="verification code"
              aria-label="verification code"
              autocomplete="off"
              maxlength="5"
              autofocus
              [ngClass]="{
                'is-invalid': formUtils.controlHasError(verificationCode)
              }"
            />
            <label for="verificationCode">verification code</label>
          </div>
          @if (formUtils.controlHasError(verificationCode, 'required')) {
            <span class="invalid-feedback"> required </span>
          }
          @if (formUtils.controlHasError(verificationCode, 'pattern')) {
            <span class="invalid-feedback"> numbers only </span>
          }

          @if (formUtils.controlHasError(verificationCode, 'server')) {
            <span class="invalid-feedback">
              {{ verificationCode.errors?.['server'] }}
            </span>
          }
        </div>
      </div>
      <div class="col-12 mb-3">
        <button
          type="button"
          class="btn btn-success w-100"
          [disabled]="submitting"
          (click)="onVerify()"
          (keyup.Enter)="onVerify()"
        >
          {{ verifyText }}
        </button>
      </div>
      @if (canResend) {
        <button
          type="button"
          class="btn btn-link"
          (click)="onResendVerification()"
        >
          didn't receive code? click here to resend
        </button>
      }
    </div>
  }
  <ng-content></ng-content>
</div>
