/*
 * Created by Kijana J. James on 11/22/23, 12:13 PM.
 * Keejware LLC, All Rights Reserved
 */

import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { FormUtils } from '../utils/form-utils';
import {
  getCountryCodeControl,
  getPhoneControl,
  PhoneNumberComponent,
} from '../phone-number/phone-number.component';
import { SnackBarAlertService } from '../snack-bar-alert/snack-bar-alert.service';
import { WindowService } from '../services/window.service';

export interface Mfa {
  phone: string;
  verificationCode: number;
}

@Component({
  selector: 'app-mfa',
  standalone: true,
  imports: [CommonModule, PhoneNumberComponent, ReactiveFormsModule],
  templateUrl: './mfa.component.html',
})
export class MfaComponent implements OnChanges, OnDestroy {
  @Input() started = false;
  @Input() submitting = false;
  @Input() startText = 'login';
  @Input() verifyText = 'verify';

  @Input() countryCode?: string;
  @Input() phone?: string;
  @Input() last4?: string;
  @Input() serverError?: HttpErrorResponse;
  @Input() reset?: Subject<void>;

  start = output<string>();
  verify = output<Mfa>();
  resendVerification = output<void>();

  canResend = false;
  phoneNumber = '';

  phoneForm = this.fb.nonNullable.group({
    countryCode: getCountryCodeControl(),
    phone: getPhoneControl(),
  });

  verificationCode = new FormControl<number | undefined>(undefined, [
    Validators.required,
    Validators.pattern(/^\d{4,10}$/),
  ]);

  formUtils = FormUtils;
  onDestroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private snackBarAlertService: SnackBarAlertService,
    private windowService: WindowService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.handleServerError(changes['serverError']?.currentValue);

    if (changes['reset']?.currentValue) {
      this.reset?.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.phoneForm.reset({
          phone: undefined,
        });
        this.verificationCode.reset(undefined);
      });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();
  }

  handleServerError(serverError: HttpErrorResponse | undefined): void {
    if (!serverError) {
      return;
    }

    if (this.tryHandlePhoneNumberError(serverError)) {
      return;
    }

    if (this.tryHandleVerificationCodeError(serverError)) {
      return;
    }

    this.formUtils.trySetFormGroupServerErrors(
      this.phoneForm,
      serverError,
      () => {
        this.snackBarAlertService.showAlert({
          message: 'oops! something unexpected went wrong. please try again.',
        });
      },
    );

    this.formUtils.trySetFormControlServerError(
      'verificationCode',
      this.verificationCode,
      serverError,
    );
  }

  tryHandlePhoneNumberError(
    serverError: HttpErrorResponse | undefined,
  ): boolean {
    const phoneError = serverError?.error?.errors?.['phone'];

    switch (phoneError) {
      case 'invalid':
        this.formUtils.setFormControlError(
          this.phoneForm.controls.phone,
          'server',
          'phone number was not found.',
        );
        return true;

      default:
        return false;
    }
  }

  tryHandleVerificationCodeError(
    serverError: HttpErrorResponse | undefined,
  ): boolean {
    const verificationCodeError =
      serverError?.error?.errors?.['verificationCode'];

    switch (verificationCodeError) {
      case 'expired':
        this.verificationCode.reset();
        this.onResendVerification();

        this.snackBarAlertService.showAlert({
          message: 'this code has expired... another one has been sent!',
        });

        return true;

      case 'invalid':
        this.formUtils.setFormControlError(
          this.verificationCode,
          'server',
          'code is invalid. please try again.',
        );
        return true;

      default:
        return false;
    }
  }

  onStart(): void {
    if (this.phoneForm.invalid) {
      return this.phoneForm.markAllAsTouched();
    }

    this.phoneNumber = `${this.phoneForm.value.countryCode}${this.phoneForm.value.phone}`;

    this.start.emit(this.phoneNumber);

    this.toggleResendButton();
  }

  onVerify(): void {
    this.verificationCode.markAllAsTouched();

    if (this.verificationCode.invalid) {
      return;
    }

    this.verify.emit({
      phone: this.phoneNumber,
      verificationCode: +(this.verificationCode.value as number),
    });
  }

  onResendVerification(): void {
    this.resendVerification.emit();
    this.toggleResendButton();
  }

  toggleResendButton(): void {
    const oneMinute = 60 * 1000;

    const enableTimeout = this.windowService.setTimeout(() => {
      this.canResend = true;
      this.windowService.clearTimeout(enableTimeout);
    }, oneMinute);

    this.canResend = false;
  }
}
